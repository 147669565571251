import { default as buttonsRMjKGxR52iMeta } from "/app/.playground/pages/buttons.vue?macro=true";
import { default as indexIjS04z3tDnMeta } from "/app/.playground/pages/forms/index.vue?macro=true";
import { default as t_45form_45edit_45staffgbuTt5NTwFMeta } from "/app/.playground/pages/forms/t-form-edit-staff.vue?macro=true";
import { default as indexRtoNoSFWZyMeta } from "/app/.playground/pages/index.vue?macro=true";
export default [
  {
    name: buttonsRMjKGxR52iMeta?.name ?? "buttons",
    path: buttonsRMjKGxR52iMeta?.path ?? "/buttons",
    meta: buttonsRMjKGxR52iMeta || {},
    alias: buttonsRMjKGxR52iMeta?.alias || [],
    redirect: buttonsRMjKGxR52iMeta?.redirect,
    component: () => import("/app/.playground/pages/buttons.vue").then(m => m.default || m)
  },
  {
    name: indexIjS04z3tDnMeta?.name ?? "forms",
    path: indexIjS04z3tDnMeta?.path ?? "/forms",
    meta: indexIjS04z3tDnMeta || {},
    alias: indexIjS04z3tDnMeta?.alias || [],
    redirect: indexIjS04z3tDnMeta?.redirect,
    component: () => import("/app/.playground/pages/forms/index.vue").then(m => m.default || m)
  },
  {
    name: t_45form_45edit_45staffgbuTt5NTwFMeta?.name ?? "forms-t-form-edit-staff",
    path: t_45form_45edit_45staffgbuTt5NTwFMeta?.path ?? "/forms/t-form-edit-staff",
    meta: t_45form_45edit_45staffgbuTt5NTwFMeta || {},
    alias: t_45form_45edit_45staffgbuTt5NTwFMeta?.alias || [],
    redirect: t_45form_45edit_45staffgbuTt5NTwFMeta?.redirect,
    component: () => import("/app/.playground/pages/forms/t-form-edit-staff.vue").then(m => m.default || m)
  },
  {
    name: indexRtoNoSFWZyMeta?.name ?? "index",
    path: indexRtoNoSFWZyMeta?.path ?? "/",
    meta: indexRtoNoSFWZyMeta || {},
    alias: indexRtoNoSFWZyMeta?.alias || [],
    redirect: indexRtoNoSFWZyMeta?.redirect,
    component: () => import("/app/.playground/pages/index.vue").then(m => m.default || m)
  }
]