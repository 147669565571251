export default defineAppConfig({
    myProject: {
      name: '@ticketme/ui'
    }
  })
  
  declare module '@nuxt/schema' {
    interface AppConfigInput {
      myProject?: {
        /** Project name */
        name?: string
      }
    }
  }
  