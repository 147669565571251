import revive_payload_client_Frd8gASYWa from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_3Bi0E2Ktsf from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CrWB4n4PyO from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_T5aD6w6QZh from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_yxgjJ9XxnV from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_aQoh9E5uaZ from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.playground/.nuxt/components.plugin.mjs";
import prefetch_client_5ZRYOjuei0 from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import unocss_MzCDxu9LMj from "/app/.playground/.nuxt/unocss.mjs";
import chunk_reload_client_kCUITUWXUN from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
export default [
  revive_payload_client_Frd8gASYWa,
  unhead_3Bi0E2Ktsf,
  router_CrWB4n4PyO,
  payload_client_T5aD6w6QZh,
  check_outdated_build_client_yxgjJ9XxnV,
  plugin_vue3_aQoh9E5uaZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5ZRYOjuei0,
  unocss_MzCDxu9LMj,
  chunk_reload_client_kCUITUWXUN
]